import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Country } from "../models/country";
import { SupplierFilter } from "../models/supplier-filter";
import { MarketSupplier } from "../models/market-supplier";
import { CommandResult } from "../models/command-result";
import { Supplier } from "../models/supplier";
import { CategoryFilter } from "../models/category-filter";
import { EquipmentTypeFilter } from "../models/equipment-type-filter";
import { EquipmentType } from "../models/equipment-type";
import { Category } from "../models/category";
import { UserFilter } from "../models/user-filter";
import { User } from "../models/user";
import { UsefulLinkList } from "../models/usefullink-list";
import { UsefulLink } from "../models/usefullink";
import { UsefulLinkFilter } from "../models/usefullink-filter";
import { ApplicationStatementModel } from "../models/application-statement";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private http: HttpClient) {}

  GetGobalSupplierListWithSelectedMarketStatus(
    startingIndex: number = 0,
    pageSize = 10,
    countryId: Array<number> = null,
    searchKey: string = null
  ): Observable<any> {
    const supplierFilter = new SupplierFilter();
    supplierFilter.countryId = countryId;
    supplierFilter.startingIndex = startingIndex;
    supplierFilter.pageSize = pageSize;
    supplierFilter.searchKey = searchKey;
    return this.http.post(
      "/api/admin/GetGobalSupplierListWithSelectedMarketStatus",
      supplierFilter
    );
  }

  GetGobalSupplierList(
    startingIndex: number = 0,
    pageSize = 10,
    searchKey: string = null
  ): Observable<any> {
    const supplierFilter = new SupplierFilter();
    supplierFilter.startingIndex = startingIndex;
    supplierFilter.pageSize = pageSize;
    supplierFilter.searchKey = searchKey;
    return this.http.post("/api/admin/GetGobalSupplierList", supplierFilter);
  }

  GetMarketSupplierList(
    startingIndex: number = 0,
    pageSize = 10,
    countryId: Array<number>,
    searchKey: string = null
  ): Observable<any> {
    const supplierFilter = new SupplierFilter();
    supplierFilter.startingIndex = startingIndex;
    supplierFilter.pageSize = pageSize;
    supplierFilter.searchKey = searchKey;
    supplierFilter.countryId = countryId;
    return this.http.post("/api/admin/GetMarketSupplierList", supplierFilter);
  }

  GetCategoryList(
    startingIndex: number = 0,
    pageSize = 10,
    searchKey: string = null
  ): Observable<any> {
    const categoryFilter = new CategoryFilter();
    categoryFilter.startingIndex = startingIndex;
    categoryFilter.pageSize = pageSize;
    categoryFilter.searchKey = searchKey;
    return this.http.post("/api/admin/GetCategoryList", categoryFilter);
  }

  GetEquipmentTypeList(
    startingIndex: number = 0,
    pageSize = 10,
    searchKey: string = null
  ): Observable<any> {
    const equipmentTypeFilter = new EquipmentTypeFilter();
    equipmentTypeFilter.startingIndex = startingIndex;
    equipmentTypeFilter.pageSize = pageSize;
    equipmentTypeFilter.searchKey = searchKey;
    return this.http.post(
      "/api/admin/GetEquipmentTypeList",
      equipmentTypeFilter
    );
  }

  GetUserList(
    startingIndex: number = 0,
    pageSize = 10,
    searchKey: string = null
  ): Observable<any> {
    const userFilter = new UserFilter();
    userFilter.startingIndex = startingIndex;
    userFilter.pageSize = pageSize;
    userFilter.searchKey = searchKey;
    return this.http.post("/api/admin/GetUserList", userFilter);
  }

  GetUsefulLinkList(
    startingIndex: number = 0,
    pageSize = 10,
    searchKey: string = null
  ): Observable<any> {
    const userLinkFilter = new UsefulLinkFilter();
    userLinkFilter.startingIndex = startingIndex;
    userLinkFilter.pageSize = pageSize;
    userLinkFilter.searchKey = searchKey;
    return this.http.post("/api/admin/GetUsefulLinkList", userLinkFilter);
  }

  GetSupplierDetailsById(supplierId: number): Observable<any> {
    return this.http.get("/api/admin/GetSupplierDetailsById/" + supplierId);
  }

  GetEquipmentTypeDetailsById(equipmentTypeId: number): Observable<any> {
    return this.http.get(
      "/api/admin/GetEquipmentTypeDetailsById/" + equipmentTypeId
    );
  }

  GetCategoryDetailsById(categoryId: number): Observable<any> {
    return this.http.get("/api/admin/GetCategoryDetailsById/" + categoryId);
  }

  GetUserDetailsById(userId: number): Observable<any> {
    return this.http.get("/api/admin/GetUserDetailsById/" + userId);
  }

  GetUsefulLinkDetailsById(usefulLinkId: number): Observable<any> {
    return this.http.get("/api/admin/GetUsefulLinkDetailsById/" + usefulLinkId);
  }

  getMasterDataForManageSuppliers(userId: number): Observable<Country[]> {
    return userId
      ? this.http.get<Country[]>("/api/admin/GetAllCountries/" + userId)
      : this.http.get<Country[]>("/api/admin/GetAllCountries");
  }

  getMasterDataForAddEditUser(): Observable<any> {
    return this.http.get<Country[]>("/api/admin/getMasterDataForAddEditUser");
  }

  UpdateMarketSupplierList(
    marketSupplier: MarketSupplier
  ): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/UpdateMarketSupplierList",
      marketSupplier
    );
  }

  GetAttachmentUrl(id, isAddendum) {
    let params = new HttpParams();
    params = params.append("id", id);
    params = params.append("isAddendum", isAddendum);
    return this.http.get("/api/admin/GetAttachmentUrl", {
      params: params,
      responseType: "blob",
    });
  }

  UpdateSupplier(supplier: Supplier): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/UpdateSupplier", supplier);
  }

  UpdateEquipmentType(equipmentType: EquipmentType): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/UpdateEquipmentType",
      equipmentType
    );
  }

  UpdateCategory(category: Category): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/UpdateCategory", category);
  }

  UpdateUser(user: User): Observable<CommandResult> {
    // authorization header
    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${localStorage.getItem("access_token")}`
    );

    return this.http.post<CommandResult>("/api/admin/UpdateUser", user, {
      headers,
    });
  }

  UpdateUsefulLink(usefulLink: UsefulLink): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/UpdateUsefulLink",
      usefulLink
    );
  }

  AddSupplier(supplier: Supplier): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/AddSupplier", supplier);
  }

  AddEquipmentType(equipmentType: EquipmentType): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/AddEquipmentType",
      equipmentType
    );
  }

  AddCategory(category: Category): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/AddCategory", category);
  }

  AddUser(user: User): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/AddUser", user);
  }

  AddUsefulLink(usefulLink: UsefulLink): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/AddUsefulLink",
      usefulLink
    );
  }

  DeleteSupplier(supplier: Supplier): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/DeleteSupplier", supplier);
  }

  DeleteCategory(category: Category): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/DeleteCategory", category);
  }

  DeleteEquipmentType(equipmentType: EquipmentType): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/DeleteEquipmentType",
      equipmentType
    );
  }

  DeleteUser(user: User): Observable<CommandResult> {
    return this.http.post<CommandResult>("/api/admin/DeleteUser", user);
  }

  DeleteUsefulLink(usefulLink: UsefulLink): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/DeleteUsefulLink",
      usefulLink
    );
  }

  isEmailAllowed(email: string, userId?: number): Observable<any> {
    return userId
      ? this.http.get("/api/admin/isEmailAllowed/" + email + "/" + userId)
      : this.http.get("/api/admin/isEmailAllowed/" + email);
  }

  GetApplicationStatementDetailsById(
    applicationStatementid: number
  ): Observable<ApplicationStatementModel> {
    return this.http.get<ApplicationStatementModel>(
      "/api/admin/GetApplicationStatementDetailsById/" + applicationStatementid
    );
  }

  UpdateApplicationStatement(
    applicationStatementModel: ApplicationStatementModel
  ): Observable<CommandResult> {
    return this.http.post<CommandResult>(
      "/api/admin/UpdateApplicationStatement",
      applicationStatementModel
    );
  }
}
